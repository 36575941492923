
import { defineComponent, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Actions } from '@/store/enums/StoreEnums';

export default defineComponent({
  name: 'auth',
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      // check if current user is authenticated
      if (store.getters.isUserAuthenticated) {
        router.push({ name: 'Dashboard' });
      }

      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'bg-body');
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'bg-body');
    });
  },
});
